import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/700.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import './App.css';
import ApiOverview from './apis/ApiOverview';
import ApiReference from './apis/ApiReference';
import ApisOverview from './apis/ApisOverview';
import Changelog from './basics/changelog/Changelog';
import APIGettingStarted from './basics/getting-started/APIGettingStarted';
import Design from './design/Design';
import Faq from './faq/Faq';
import FlowDetails from './flows/FlowDetails';
import FlowsOverview from './flows/FlowsOverview';
import Home from './home/Home';
import Layout from './layout/Layout';
import Contact from './legal/Contact';
import Imprint from './legal/Imprint';
import Login from './login/Login';
import APIReferenceRenderer from './spec-preview/APIReferenceRenderer';
import SpecPreview from './spec-preview/SpecPreview';

function App() {
  const client = new QueryClient();

  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'flix');
  const themeToggle = () => {
    const newTheme = theme === 'flix' ? 'flix-dark' : 'flix';
    localStorage.setItem('theme', newTheme);
    setTheme(newTheme);
  };

  return (
    <div className="app">
      <AuthenticatedTemplate>
        <QueryClientProvider client={client}>
          <BrowserRouter>
            <Layout theme={theme} themeToggleHandler={themeToggle}>
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path="/flows">
                  <FlowsOverview withBreadcrumbs />
                </Route>
                <Route exact path="/apis">
                  <ApisOverview withBreadcrumbs />
                </Route>
                <Route exact path="/apis/:id">
                  <ApiOverview />
                </Route>
                <Route path="/apis/:id/reference">
                  <ApiReference theme={theme} />
                </Route>
                <Route path="/apis/:id/changelog">
                  <Changelog theme={theme} />
                </Route>
                <Route path="/apis/:id/getting-started">
                  <APIGettingStarted theme={theme} />
                </Route>
                <Route path="/flows/:id/overview">
                  <FlowDetails theme={theme} />
                </Route>
                <Route path="/flows/:id/getting-started">
                  <FlowDetails theme={theme} />
                </Route>
                <Route path="/imprint">
                  <Imprint />
                </Route>
                <Route path="/contact">
                  <Contact />
                </Route>
                <Route path="/design">
                  <Design />
                </Route>
                <Route path="/faq">
                  <Faq />
                </Route>
                <Route exact path="/preview">
                  <SpecPreview />
                </Route>
                <Route exact path="/preview/reference">
                  <APIReferenceRenderer theme={theme} />
                </Route>
              </Switch>
            </Layout>
          </BrowserRouter>
          {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
        </QueryClientProvider>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </div>
  );
}

export default App;
