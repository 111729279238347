import { Icon, IconNewTab } from '@flixbus/honeycomb-icons-react';
import { Link } from '@flixbus/honeycomb-react';

const htmlRenderer = (htmlText) => {
  const anchorTagRegex = /<a href="([^"]+)"[^>]*>(.*?)<\/a>/g;

  // Split the text and replace the anchors
  const parts = htmlText.split(anchorTagRegex);

  return parts.map((part, index) => {
    if (index % 3 === 0) {
      // Plain text part
      return part;
    }
    if (index % 3 === 1) {
      // URL part (from the href attribute)
      const url = part;
      const text = parts[index + 1]; // Text between the <a> tags

      // Check if the URL is a 'mailto:' link
      if (url.startsWith('mailto:')) {
        return (
          <Link key={index} href={url}>
            {text} <Icon style={{ fill: 'currentColor' }} InlineIcon={IconNewTab} aria-label="Opens in a New Tab" />
          </Link>
        );
      } else {
        // Regular 'https' link
        return (
          <Link key={index} target="_blank" href={url}>
            {text} <Icon style={{ fill: 'currentColor' }} InlineIcon={IconNewTab} aria-label="Opens in a New Tab" />
          </Link>
        );
      }
    }
    return null;
  });
};

export default htmlRenderer;
